import { useState,useEffect } from 'react';


// https://rweb-static.dagostino-bruno.com.ar/instructivos/
export const InstructivoCard = ({name, alone, urlPdf}) => {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState('');

  const [download, setDownload] = useState(false);

  useEffect(() => {
    if (download) {
      const link = document.createElement("a");
      link.href =
        `https://rweb-static.dagostino-bruno.com.ar/instructivos/${urlPdf}`;
      link.setAttribute("download", name + ".pdf");
      link.setAttribute("target", "_blank");
      link.click();
      setDownload(false);
    }
  }, [download]);
  
  return (
    <button  onClick={() => setDownload(true)} className={`${alone? "lg:col-start-2": "col-auto"} button test-card bg-white text-darkBlue`}>
        <p className="min-w-max font-bold xs:text-sm sm:text-base md:text-2xl">{name}</p>
    </button>
  );
}

export default InstructivoCard;