import axios from "../../interceptor/ApiInterceptor";
import moment from "moment"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"

import scheduleActions from "../../redux/actions/schedule";
import othersActions from "../../redux/actions/others";
import authActions from "../../redux/actions/auth";
import toast from "react-hot-toast";
import { shops, tests } from "../../data/appointments";
import { MaterialIcon } from "../../components/Icons";
import {lookupPhone} from '../../libs/twilio'

export const useAgenda = () => {
  const BE_URL = process.env.REACT_APP_JAVA_BE_URL
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const appointment = useSelector(state => state.schedule)
  const isLoading = useSelector(state => state.others.isLoading)

  const [files, setFiles] = useState({credential: [], authorizations: []})

  return {
    BE_URL,
    navigate,
    dispatch,
    user,
    appointment,
    isLoading,
    setFiles,
    files
  }
}

export const useConfirmAppointment = (files) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const appointment = useSelector(state => state.schedule)
  const isLoading = useSelector(state => state.others.isLoading)
  const [canWhatsapp, setCanWhatsapp] = useState(true)
  const [isValidPhone, setIsValidPhone] = useState(false)

  const isDomi = appointment?.shop?.value.includes('DOMI')
  
  const validatePhone = async () => {
    const output = await lookupPhone(user?.phone)
    setCanWhatsapp(output?.mobile)
    setIsValidPhone(output?.mobile)
  }

  const BE_URL = process.env.REACT_APP_JAVA_BE_URL

  const validateUser = () => {
    const url = `${BE_URL}/external/api/v1/security/prelogin`
    
    const parseJwt = (token) => { try { return JSON.parse(atob(token.split('.')[1])); } catch (e) { return false; } };
    const payload = {idType: appointment?.patient?.idType, idNumber: appointment?.patient?.idNumber}

    axios.post(url, payload)
      .then(res => {
        if(res.data && res.data !== "") {
          // console.log(parseJwt(res.data));
          schedule(true, parseJwt(res.data))
        } else {
          schedule(false)
        }
      })
      .catch(error => {
        console.log(error);
        toast({
          title: "Hubo un error validando tus datos, reintenta nuevamente.", 
          desc: "Si el error persiste puedes comunicarte con nosotros por whatsapp",
          type: "error",
        })
        dispatch(othersActions.setIsLoading(false))
      })
  }

  const schedule = (exist, userToken) => {
    
    const url = `${BE_URL}/external/api/v2/addressschedule/register2/${appointment.hour.id}`
    const phoneNUmber =appointment.patient.phone
    const userObject = { 
      idType: user ? user?.idType : appointment?.patient?.idType, 
      idNumber: user ? user?.idNumber : appointment?.patient?.idNumber, 
      name: user ? user?.name : appointment?.patient?.name, 
      surname: user ? user?.surname : appointment?.patient?.surname, 
      birthDate: user ? user?.birthDate : appointment?.patient?.birthDate, 
      gender: user ? user?.gender.toUpperCase() : appointment?.patient?.gender, 
      mail: user ? user?.mail : appointment?.patient?.mail, 
      role: user ? user?.role : "USER_ROL_STANDARD", 
      streetName: appointment?.streetName ? appointment?.streetName : "",
      streetNumber: appointment?.streetNumber ? appointment?.streetNumber : "",
      streetDescription: appointment?.streetDescription ? appointment?.streetDescription : "",
      city: appointment?.city ? appointment?.city : "",
      phone: user ? user?.phone : phoneNUmber.includes('+') ? phoneNUmber.split('+')[1] : phoneNUmber, 
    }
    
    const userPayload = (user) ? {...userObject, id: user?.id} : (userToken?.id ? {...userObject, id: userToken.id} : userObject)

    const payload = { 
      user: userPayload, 
      scheduleType : appointment?.test?.code,
      healthInsurance: {id: parseInt( appointment?.healthInsurance?.id)}, 
      files: [...files?.credential, ...files?.authorizations]
    }

    axios.post(url, payload)
      .then(res => {
        if(res.data.user) {
          if(!user?.id) {
            if(exist) {
              toast({
                title: "Iniciá sesión para gestionar tus datos y los de tus turnos.", 
                desc: "Tenés una cuenta activa, si querés realizar cambios al turno recién agendado debés iniciar sesión",
                type: "success",
                icon: <MaterialIcon icon="campaign" extrastyles="text-white text-[30px]" />
              })
            } else {
              dispatch(authActions.authUser({
                ...res.data.user,
                gender: res.data?.user?.gender.toUpperCase(),
                birthDate: res.data?.user?.birthDate.split("T")[0],
              }))
              toast({
                title: "Inicio de sesión automático",
                desc: "Como es tu primer turno se ejecutó un inicio automático en tu nueva cuenta. Por favor verificá el turno y tus datos, una vez se cierre la sesión no podrás ejecutar cambios",
                type: "success",
                icon: <MaterialIcon icon="campaign" extrastyles="text-white text-[30px]" />
              })
          }
          }

          dispatch(scheduleActions.clearAppointmentSS())
          navigate(`/agenda/confirmacion/${res.data.tokenId}`)
          dispatch(othersActions.setIsLoading(false))
        } else {
          throw new Error('Error registrando el turno o al usuario')
        }
      })
      .catch(e => {
        console.log(e);
        dispatch(othersActions.setIsLoading(false))

        console.log(e?.response?.data?.errorMessage);
        if(e?.response?.data?.errorMessage.includes("turno")){
          toast({
            title: "No podés agendar más turnos", 
            desc: "Tenés 2 turnos pendientes, para seguir agendando cancela o asiste a un turno.",
            type: "error",
            icon: <MaterialIcon icon="error" />
          })
        } else {
          toast({
            title: 'Ocurrió un error', 
            desc: `Intentá nuevamente en unos minutos`,
            type: "error",
            icon: <MaterialIcon icon="error" />
          })
        }
      })
  }  

  const confirmData = () => {
    dispatch(othersActions.setIsLoading(true))
    user ? schedule(true) : validateUser()
  }


  useEffect(() => {
    //dispatch(othersActions.setIsLoading(true))
    //searchAppointment()
    validatePhone()
  }, [])

  const dateBirth = user ? user?.birthDate : appointment?.patient?.birthDate
  const birthDate = moment(dateBirth).format('DD/MM/Y')
  
  const preDate = moment(appointment?.date)
  
  
  const hour = isDomi ? `${appointment?.hour?.hour}:${appointment?.hour?.minute} am a ${parseInt(appointment?.hour?.hour)+4}:${appointment?.hour?.minute} am` : `${appointment?.hour?.hour}:${appointment?.hour?.minute === 0 ? "00" : appointment?.hour?.minute} hs`
 
 
  const date = `${preDate.format('dddd D')} de ${preDate.format('MMMM')} - ${hour}`


  return { date, appointment, isLoading, birthDate, confirmData, isDomi }
}
//_------------------------------------------------------------------------------------//////////
export const useConfirmedShift = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const user = useSelector(state => state.auth.user)
  const [appointment, setAppointment] = useState(false)
  const isLoading = useSelector(state => state.others.isLoading)
  const [isDomi, setIsDomi] = useState(false)

  const {REACT_APP_JAVA_BE_URL} = process.env
  
  const searchAppointment = () => {
    const url = `${REACT_APP_JAVA_BE_URL}/external/api/v2/addressschedule/byToken/${params.tokenId}`

    axios.get(url)
      .then(res => {
        // console.log(res.data);
      
        const hour = res.data.scheduleRange.hour
        const minute = res.data.scheduleRange.minute === 0 ? "00" : res.data.scheduleRange.minute

        const shop = shops.find(shop => res.data.scheduleRange.schedulerEngine.shop.startsWith(shop.value))
        const test = tests.find(test => test.code.includes(res.data.scheduleType))

        const shopIsDomi = res.data.scheduleRange.schedulerEngine.shop.includes('DOMI')

        // const shopSudor = shops.find(sudor => sudor.value === "SUC13")
        
        if(shopIsDomi){ setIsDomi(true)}


        setAppointment({
          date: res.data.scheduleRange?.schedulerEngine?.date,
          //shop: !shopIsDomi ? (test.value === "test-sudor") ? shopSudor.label: shop.label : `Calle ${res.data?.user?.streetName ? res.data?.user?.streetName : ""} N ${res.data?.user?.streetNumber ? res.data?.user?.streetNumber : ""}, La Plata`,
          shop: !shopIsDomi ? shop.label : `Calle ${res.data?.user?.streetName ? res.data?.user?.streetName : ""} N ${res.data?.user?.streetNumber ? res.data?.user?.streetNumber : ""}, La Plata`,
          address: `${shop.place.address} ${shop.place.city}`,
          
          //test: (test.value != "test-sudor") ? `${test.name} ${shopIsDomi ? ' a domicilio': ''}` : `${shopSudor.place.address} ${shopSudor.place.city}`,
          test: `${test.name} ${shopIsDomi ? ' a domicilio': ''}`,
          isDomi: shopIsDomi,
          user: res.data.user,
          range: {hour, minute},
          insurance: res.data?.healthInsurance?.name ? res.data?.healthInsurance?.name.charAt(0).toUpperCase() + res.data?.healthInsurance?.name.slice(1).toLowerCase() : undefined
        })

        dispatch(othersActions.setIsLoading(false))
      })
      .catch(error => {
        console.log(error);
        dispatch(othersActions.setIsLoading(false))
      })
  }

  useEffect(() => {
    dispatch(othersActions.setIsLoading(true))
    searchAppointment()
    //validatePhone()
  }, [])

  const clearAppointment = () => {
    dispatch(scheduleActions.clearAppointmentSS())
    navigate('/agenda')
  }

  const preDate = moment(appointment ? appointment?.date : new Date())
  const hour = appointment ? (appointment?.isDomi ? `Entre ${appointment?.range?.hour}:${appointment?.range?.minute} am y ${parseInt(appointment?.range?.hour)+4}:${appointment?.range?.minute} am` : `${appointment?.range?.hour}:${appointment?.range?.minute} hs`) : ""
  const date = `${preDate.format('dddd D')} de ${preDate.format('MMMM')} - ${hour}`

  return { date, clearAppointment, appointment, isLoading, isDomi }
}
