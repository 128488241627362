import { useFormik } from "formik"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from 'yup';
import axios from "../../interceptor/ApiInterceptor";
import authActions from "../../redux/actions/auth";
import { lookupPhone } from "../../libs/twilio"

export const useUpdateUserInfo = (setModalUserData) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const BE_URL = process.env.REACT_APP_JAVA_BE_URL

  const [isSaving, setIsSaving] = useState(false)
  const [twilioError, setTwilioError] = useState("")
  const [msg, setMsg] = useState({text: "", color: ""})

  const patientSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Escribe más de 2 carácteres como mínimo').max(50, 'Too Long!').required('Este campo es obligatorio'),
    surname: Yup.string().min(2, 'Escribe más de 2 carácteres como mínimo').max(50, 'Too Long!').required('Este campo es obligatorio'),
    idType: Yup.string().required('Este campo es obligatorio').oneOf(['DNI', 'PAS', 'CI']),
    phone: Yup.number().required('Este campo es obligatorio').min(1000000000, 'Este campo es obligatorio'),
    gender: Yup.string().required('Este campo es obligatorio'),
    mail: Yup.string().email('Verifica que sea un email válido').required('Este campo es obligatorio'),
    idNumber: Yup.string().when('idType', {
        is: "DNI",
        then: schema => schema.min(6, 'Tu DNI debe ser mayor o igual a 400000').max(8, 'Tu DNI debe ser menor o igual a 99000000'),
      }).required('Este campo es obligatorio'),
    birthDate: Yup.string().required('Este campo es obligatorio')//.max(new Date(), 'La fecha declarada no puede ser posterior a hoy').min(new Date("01-01-1900"), "La fecha declarada debe ser posterior a 01/01/1900")
  });

  const userPhone = user?.phone.replace('+', '')

  const formik = useFormik({
    initialValues: ({
      ...user, 
      phone: `+${(userPhone.length === 10) ? `549${userPhone}` : userPhone}`
    }),
    validationSchema: patientSchema,
    onSubmit: async values => {
      setIsSaving(true)
  
      const newPhone = values.phone.includes('+') ? values.phone.split('+')[1] : values.phone
      const output = await lookupPhone(newPhone)
  
      if(!output?.mobile) {
        setTwilioError("Por favor ingresá un número móvil válido")
        setIsSaving(false)
        return null
      } else {
        setTwilioError("")
        const newData = { ...values, phone: newPhone, birthDate: values.birthDate, oldUser: false}
        const url = `${BE_URL}/external/api/v1/users/${user.id}`
        const payload = { ...user, ...newData }

        // console.log(payload);
  
        axios.put(url, payload, { headers: { Authorization: `Bearer ${user?.token}` } })
          .then(res => {
            // console.log(res.data);
            dispatch(authActions.authUser({ ...user, ...newData }))
            setMsg({text: "Se actualizó la información correctamente", color: "text-green-400"})
            setIsSaving(false)
            setModalUserData(false)
          })
          .catch(error => {
            console.log(error);
            setIsSaving(false)
            setMsg({text: "Hubo un error guardando la información", color: "text-error"})
            if(error.response && error.response.status === 401) dispatch(authActions.signOut(true))
          })
      }
    }
  })

  return {
    formik,
    twilioError,
    isSaving,
    msg
  }
}
