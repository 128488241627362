import { Link } from "react-router-dom";
import moment from 'moment';

const AuditoriasRow = ({data: {auditorias}}) => {

  return (
    <tr className='border-t border-lightGrey'>
      <td className='p-6'>{auditorias.transaccion}</td>
      <td className='p-6'>{moment(auditorias.fechaPrescripcion).format('DD/MM/YY')}</td>
      <td className='p-6'>{auditorias.estado}</td>
      <td className='p-6'> 
        <Link to={`/detalle/${auditorias.transaccion}`}
          className=' bg-cyan text-white p-4 rounded-lg shadow-8 text-sm my-3'
        >
          Detalle
        </Link>      
      </td>
    </tr>
  )
}

const AuditoriasTable = ({data: {auditorias}}) => {

  return (
    <div className='lg:block w-full flex-1 text-lg text-left !text-[15px]'>
      
      <table className='flex-1 w-full'>
        <thead className='text-deactivatedDarkBlue'>
          <tr>
            <th className='font-normal pb-3'># Transacción</th>
            <th className='font-normal pb-3'>Fecha de Prescripción</th>
            <th className='font-normal pb-3'>Estado</th>
            <th className='font-normal pb-3'>Operación</th>
          </tr>
        </thead>
        <tbody className='text-darkBlue'>
          {auditorias.map((auditorias, index) => (
            <AuditoriasRow key={index} data={{auditorias}} />)
          )}
        </tbody>
      </table>
    </div>
  )
}

const AuditoriasList = ({data}) => {
  
  return (
    <div className='w-full flex-1'>
      <AuditoriasTable data={data} />
    </div>
  )
}

export default AuditoriasList