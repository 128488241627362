const AuditoriasDetalleRow = ({ data: {detalle, isIoma}}) => {
    console.log(detalle.namePractices)
    console.log(detalle.isIoma, 'Estoy en AudotirasDetalleRow')

    return (
      <tr className='border-t border-lightGrey'>
        <td className='p-4 m-4'>{detalle.namePractices}</td>
        <td className='p-4 m-4'>{detalle.status}</td>
        {!isIoma && <td className='p-4 m-4'>${detalle.cost}</td>}
      </tr>
    )
  }
  
  const AuditoriasDetalleTable = ({ data: { auditoriasDetalle, isIoma, errorState } }) => {
    if (errorState?.error) {
      return (
        <div className="text-2xl font-bold p-4">
          {errorState.errorMessage + '.' || 'Ha ocurrido un error al cargar los detalles de auditoría.'}
        </div>
      );
    }
    console.log(isIoma, 'Estoy en audotirasDetalleTable')
    return (
      <div className=' lg:block w-full flex-1 text-lg text-left !text-[15px]'>
        
        <table className='flex-1 w-full'>
          <thead className='text-deactivatedDarkBlue'>
            <tr>
              <th className='font-normal pb-3'>Nombre practica</th>
              <th className='font-normal pb-3'>Autorizado</th>
              {!isIoma && <th className='font-normal pb-3'>Valor</th>}
            </tr>
          </thead>
          <tbody className='text-darkBlue'>
            {auditoriasDetalle?.practicas?.map((detalle, index) => 
              <AuditoriasDetalleRow key={index} data={{detalle, isIoma}} />
            )}
            {/* {auditoriasDetalle.map((auditoriasDetalle, index) => (
              <AuditoriasDetalleRow key={index} data={{auditoriasDetalle}} />)
            )} */}
            <tr className="border-t border-darkGrey">
              {auditoriasDetalle.total ? <th className='text-2xl font-bold p-4'>{auditoriasDetalle.total}</th> : <th className='text-2xl font-bold p-4'>Total: $0</th>}
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
  
  const AuditoriasDetalleList = ({data}) => {
    return (
      <div className='w-full flex-1'>
        <AuditoriasDetalleTable data={data} />
      </div>
    );
  }
  
  export default AuditoriasDetalleList