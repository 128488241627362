import moment from 'moment'
import { BoxAlert } from '../../../components/Alerts'
import { useConfirmedShift } from '../customHooks'
import { Link } from 'react-router-dom'
import { IndexModal } from "../../../components/Modals"
import { useState } from "react"



const ConfirmedShift = () => {
  const {date, appointment, clearAppointment, isLoading, isDomi} = useConfirmedShift()

  // Mostrar el modal al entrar al path /agenda/confirmacion
  const [showIndexModal, setShowIndexModal] = useState(true);
  const preDate = moment(new Date(appointment?.date));
  const isGorina = appointment?.shop?.includes('Gorina');
  
  // Calcular el gap dependiendo de las condiciones
  const gap = isGorina
  ? preDate.day() === 6 // Si es sábado
    ? 3 // 3 horas para GORINA los sábados
    : 4 // 4 horas para GORINA en otros días
  : 4; // 4 horas para las demás tiendas

  // // Formatear la fecha con las horas calculadas
  
  const hour = appointment?.range?.hour ?? 0; // Convertir a número directamente
  const minute = appointment?.range?.minute ?? "00";
  const formattedMinute = String(minute).padStart(2, "0");
  const [onlyDate, initialTime] = date.split(" - "); 

  let date2;
  if (isGorina) {
    date2 = `${onlyDate} - Entre ${initialTime} y ${hour + gap}:${formattedMinute} hs`;
  } else {
    date2 = date; // Mantiene el formato original si no es Gorina
  }

  return (
    <>
    <div className='mx-auto max-w-max min-w-max'>
      <div className='flex items-centr gap-2 mb-5'>
        <div className='flex lg:hidden bg-cyan shadow-2 rounded-lg items-center justify-center h-min h-6 md:h-8'>
          <svg width="83" className='h-3 w-8' height="60" viewBox="0 0 83 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M81.263 1.76082C83.579 4.10482 83.579 7.89274 81.263 10.2367L33.8318 58.242C31.5158 60.586 27.7732 60.586 25.4572 58.242L1.73643 34.2394C-0.578809 31.8954 -0.578809 28.1075 1.73643 25.7634C4.05203 23.4194 7.80577 23.4194 10.1217 25.7634L29.4778 45.5094L72.8884 1.76082C75.2044 -0.586939 78.947 -0.586939 81.263 1.76082Z" fill="white"/>
          </svg>
        </div>
        <div className=''>
          <h1 className='font-bold text-xl sm:text-[28px] xl:text-4xl'>
            Tu turno <span className='text-cyan'>{isDomi ? 'ha sido solicitado' : 'está confirmado'}</span>
          </h1>
          {(!isLoading && appointment) && <p className='hidden md:block text-grey mt-1 text-lg xl:text-[25px]'>
            Te enviamos un recordatorio a <span className='font-bold'>{appointment.user.mail}</span>
          </p>}
        </div>
      </div>
      <div className='flex flex-col lg:flex-row gap-5 justify-center max-w-[850px]'>
        
        <div className='hidden lg:flex bg-cyan shadow-2 px-2 py-6 lg:py-10 rounded-lg items-center justify-center'>
          <svg width="83" className='h-9' height="60" viewBox="0 0 83 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M81.263 1.76082C83.579 4.10482 83.579 7.89274 81.263 10.2367L33.8318 58.242C31.5158 60.586 27.7732 60.586 25.4572 58.242L1.73643 34.2394C-0.578809 31.8954 -0.578809 28.1075 1.73643 25.7634C4.05203 23.4194 7.80577 23.4194 10.1217 25.7634L29.4778 45.5094L72.8884 1.76082C75.2044 -0.586939 78.947 -0.586939 81.263 1.76082Z" fill="white"/>
          </svg>
        </div>

        {(!isLoading && appointment) && <div className='flex flex-col lg:flex-row gap-5 w-full w-[288px] 400:w-[380px] 500:w-[470px] 600:w-[570px] md:w-full'>

          <div className='bg-darkBlue shadow-2 p-4 lg:p-6 rounded-lg flex flex-col justify-between gap-4'>
            <div className='flex flex-col justify-center gap-4'>
              <div>
                <span className='text-white opacity-50 mb-1 uppercase text-sm'>Análisis</span>
                <p className='text-white text-lg'>{appointment.test}</p>
              </div>
              {isDomi ? (
                <div>
                  <span className='text-white opacity-50 mb-1 uppercase text-sm'>Fecha y hora</span>
                  <p className='text-white text-lg'>Nos estaremos comunicando a la brevedad  para coordinar el día de la visita</p>
                </div>
              ):(
                <div>
                  <span className='text-white opacity-50 mb-1 uppercase text-sm'>Fecha y hora</span>
                  <p className='text-white text-lg'>{date2}</p>
                </div>
              )}
              <div>
                <span className='text-white opacity-50 mb-1 uppercase text-sm'>Domicilio de extracción</span>
                <p className='text-white text-lg'>{appointment.shop}</p>
              </div>
            </div>

            {isDomi &&  <BoxAlert data={{
              description: "Nos contactaremos a la brevedad para confirmar día y hora del turno a domicilio.",
              type: "info",
              extraContainerStyles: ""
              
            }} />}
          </div>

          <div className='bg-white shadow-2 p-4 md:p-6 rounded-lg flex-1 lg:max-w-[500px]'>
            <h5 className='uppercase font-bold mb-3 text-lg'>Datos personales</h5>
            <div className='flex flex-col gap-1'>
              <div>
                <span className='text-grey uppercase text-[10px]'>Nombre y apellido</span>
                <p className='text-lg'>{appointment.user.name} {appointment.user.surname}</p>
              </div>
              <div>
                <span className='text-grey uppercase text-[10px]'>Documento</span>
                <p className='text-lg'>{appointment.user.idType} {appointment.user.idNumber}</p>
              </div>
              <div>
                <span className='text-grey uppercase text-[10px]'>Nacimiento</span>
                <p className='text-lg'>{moment(appointment.user.birthDate).format('DD/MM/Y')}</p>
              </div>
              <div>
                <span className='text-grey uppercase text-[10px]'>Teléfono</span>
                <p className='text-lg'>{appointment.user.phone}</p>
              </div>
              {appointment.insurance && <div>
                <span className='text-grey uppercase text-[10px]'>Cobertura</span>
                <p className='text-lg'>{appointment.insurance}</p>
              </div>}
              <div>
                <span className='text-grey uppercase text-[10px]'>Email</span>
                <p className='text-lg'>{appointment.user.mail}</p>
              </div>
            </div>
          </div>
        </div>}
      </div>
      <div className='flex-1 flex items-center justify-between gap-9 pt-4 lg:max-w-[850px] md:pb-8'>
        <Link to='/instructivos' className="underline underline-offset-[6px] text-sm text-cyan font-bold">Para consultar los preparativos de los análisis HAGA CLICK AQUÍ</Link>
        <button 
          onClick={clearAppointment}
          className="underline underline-offset-[6px] text-darkBlue opacity-70 text-lg font-bold"
        >
          Sacar otro turno
        </button>
        {/* <button className="underline underline-offset-8 text-deactivatedDarkBlue text-[20px] font-bold">Descargar turno</button> */}
      </div>
    </div>
    {showIndexModal && (
        <IndexModal
          title="ATENCIÓN"
          onClose={() => {
            setShowIndexModal(false);
          }}
          details={"Es necesario concurrir a la extracción con el DNI y la orden original."}
        />
      )}  
    </>
  )
}

export default ConfirmedShift
