import axios from "../../../interceptor/ApiInterceptor";
import moment from "moment"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import scheduleActions from "../../../redux/actions/schedule"

export const useSchedule = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const appointment = useSelector(state => state.schedule)
  const isLoading = useSelector(state => state.others.isLoading)
  const BE_URL = process.env.REACT_APP_JAVA_BE_URL

  const hours3 = 10800000

  const [dates, setDates] = useState([])
  const [ranges, setRanges] = useState([])
  const [showExtractionsModal, setShowExtractionsModal] = useState(false)
  const [showSaturdaysModal, setShowSaturdaysModal] = useState(false)
  const [showFridaysModal, setShowFridaysModal] = useState(false)
  const [showThuesdaysModal, setShowThuesdaysModal] = useState(false)
  const [showExtractionsBloodModal, setShowExtractionsBloodModal] = useState(false);
  const [showMicologicoModal, setShowMicologicoModal] = useState(false)
  // const [showSudorModal, setShowSudorModal] = useState(false)
  const [loading, setLoading] = useState({ranges: false, calendar: false})
  const [errors, setErrors] = useState({calendar: "", ranges: ""})

  const updateAppointment = (info) => dispatch(scheduleActions.updateAppointment(info))

  useEffect(() => {
    
    const shop = appointment?.test?.shops?.find(shop => !shop.disabled)
    if(shop && !appointment?.shop?.value) {
      updateAppointment({shop})
      searchDates(shop?.value)    
    }

    if(appointment?.shop?.value) {
      updateAppointment({shop: appointment?.shop})
      searchDates(appointment?.shop?.value, appointment?.date)   
    }
  }, [])

  const searchDates = (shop, date = false) => {
    setLoading({...loading, calendar: true})
    setRanges([])

    
    const todayMs = new Date().getTime()
    const url = `${BE_URL}/external/api/v2/addressschedule/list/ranges/availability?dateMs=${todayMs}&shop=${shop}`

    axios.get(url)
      .then(res => {
        if(res.data.ok) {
          const responseDates = res.data.data.map
          const formatedResponseDates = Object.keys(responseDates).map((key) => {
            return {date: key, enabled: responseDates[key]}
          }).filter(date => {
            const dateMs = new Date(date.date).getTime()
             return date.enabled && (dateMs > (todayMs - 86400000 + hours3))
          })

          if(formatedResponseDates.length > 0) {
            updateAppointment({
              date: date ? date : new Date( new Date(formatedResponseDates[0]?.date).getTime() + hours3 )
            })
            searchRangesByDay(date ? date : new Date( new Date(formatedResponseDates[0]?.date).getTime() + hours3 ), shop, appointment?.hour, date ? true : false)
          }

          setDates(formatedResponseDates)
          setErrors({...errors, calendar: ""})
        } else {
          throw new Error("Hubo un error actualizando el calendario, vuelve a intentarlo nuevamente.")
        }

        setLoading({...loading, calendar: false})
      })
      .catch(error => {
        console.log(error)
        setErrors({...errors, calendar: error.message})
        setLoading({...loading, calendar: false})
      })
  }

  const orderHours = (a, b) => parseInt(a.hour) - parseInt(b.hour) || parseInt(a.minute) - parseInt(b.minute)


  const searchRangesByDay = (date, shop, hour = false, getSavedData = false) => {
    if(date && shop) {
      setLoading({...loading, ranges: true})
      const chosenDateMs = new Date(date).getTime()

      
      const url = `${BE_URL}/external/api/v2/addressschedule/list/ranges?filter=true&dateMs=${chosenDateMs}&shop=${shop}`

      axios.get(url)
        .then(res => {
          if(res.data.ok) {
            const ranges_response = res.data.data.ranges.sort(orderHours)
            const availables_ranges = ranges_response.filter(range => range.assigned < range.maxAvailable)
            const first_range = availables_ranges[0]

            updateAppointment({hour: (hour && getSavedData) ? hour : first_range})

            setRanges(ranges_response)
            setErrors({...errors, ranges: ""})
            setLoading({...loading, ranges: false})
          } else {
            throw new Error("Hubo un error actualizando los horarios, vuelve a intentarlo nuevamente.")
          }
        })
        .catch(error => {
          console.log(error)
          setErrors({...errors, ranges: error.message})
          setLoading({...loading, ranges: false})
        })
    }
  }

  useEffect(() => {
    const value = appointment?.test?.value
    if(value === 'extraccion-muestra') setShowExtractionsModal(true)
    // if(value === 'test-sudor')  setShowSudorModal(true)
    if(value === 'examen-micologico') setShowMicologicoModal(true)
  }, [appointment.test])

  return {
    appointment, 
    dates,
    ranges,

    updateAppointment, 
    searchDates, 
    searchRangesByDay, 

    showExtractionsModal, 
    setShowExtractionsModal,
    showSaturdaysModal,
    setShowSaturdaysModal,
    showThuesdaysModal,
    setShowThuesdaysModal,
    showMicologicoModal,
    setShowMicologicoModal,
   
    showFridaysModal, 
    setShowFridaysModal,
    showExtractionsBloodModal,
    setShowExtractionsBloodModal,

    loading,
    isLoading
  }
}
