import './App.css'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import toast, { Toaster } from "react-hot-toast";

import scheduleActions from './redux/actions/schedule'
import authActions from './redux/actions/auth'

// Routes-Pages // import Name from './routes/Name'
import ChooseExam from './routes/Agenda/ChooseExam'
import PatientForm from './routes/Agenda/PatientForm'
import ConfirmedShift from './routes/Agenda/ConfirmedShift'

// Logged user
import Appointments from './routes/Appointments'
import Results from './routes/Results'
import Auditorias from './routes/Auditorias'
import AuditoriasDetalle from './routes/AuditoriasDetalle'

// Components // import Name from './components/Name' 
import Patients from './routes/Patients'
import { AppLayout, ScheduleLayout } from './layouts'
import { DefaultToast } from './components/CustomToasts';
import NoMatch from './routes/NoMatch';
import AppointmentSchedule from './routes/Agenda/AppointmentSchedule';
import InsuranceForm from './routes/Agenda/InsuranceForm';
import InsuranceFormFromWP from './routes/Agenda/AppointmentFromWP';

import CancelAppointment from './routes/CancelAppointment';
import moment from 'moment';
import othersActions from './redux/actions/others';
import ConfirmAppointment from './routes/Agenda/ConfirmedShift/Confirm';
import { roles } from './data/enums';
import { FormUserInfo } from './components/Modals';
import ProfessionalRegister from './routes/ProfessionalRegister';
import LoginPage from './routes/Login';
import { getStorage } from './libs/getStorage';
import Maintenance from './components/Maintanance';
import { Instructivos } from './components/Instructivos/Instructivos';
const App = () => {
  const user = useSelector(state => state.auth.user)
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const isModalUserData = useSelector(state => state.others.isModalUserData)
  const setModalUserData = (value) => dispatch(othersActions.setModalUserData(value))

  moment.updateLocale('en', {
    weekdays: ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sábado"],
    months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
  })

  useEffect(() => {
    if (location.pathname === "/") navigate('/agenda')
    getStorage(dispatch)
  }, [])

  const inMaintenance = false;

  return (
    <>
      {inMaintenance ? <Maintenance /> :
        <>
          {(isModalUserData && user) && <FormUserInfo setModalUserData={setModalUserData} />}
          <Toaster position="bottom-center" containerStyle={{ bottom: 80 }}>
            {(t) => {
              return (
                <DefaultToast
                  title={t.message.title}
                  description={t.message.desc}
                  onClose={() => toast.dismiss(t.id)}
                  type={t.message.type}
                  icon={t.message.icon}
                >
                  {t.message.children}
                </DefaultToast>
              );
            }}
          </Toaster>
          <Routes>
            <Route path="/" element={<AppLayout />}>
              <Route path="agenda" element={<ScheduleLayout />} >
                <Route index element={<ChooseExam />} />
                <Route path="calendario" element={<RequireAuthUser><AppointmentSchedule /></RequireAuthUser>} />
                <Route path="paciente" element={<PatientForm />} />
                <Route path="cobertura" element={<InsuranceForm />} />
                <Route path="coberturaFromWP" element={<InsuranceFormFromWP/>} />
                <Route path="confirmacion">
                  <Route index element={<ConfirmAppointment />} />
                  <Route path=":tokenId" element={<ConfirmedShift />} />
                </Route>
              </Route>
              <Route path='/instructivos' element={<Instructivos/>}/>
              <Route path="cancelar/:tokenId" element={<CancelAppointment />} />

              <Route path="/login" element={<LoginPage />} />
              <Route path="/login/:idType/:idNumber" element={<LoginPage />} />

              <Route path="registro-profesionales" element={<ProfessionalRegister />} />

              {/* all user pages */}
              <Route path="resultados" element={<RequireAuthUser><Results /></RequireAuthUser>} />
              <Route path="turnos" element={<RequireAuthUser><Appointments /></RequireAuthUser>} />
              <Route path="auditorias" element={<RequireAuthUser><Auditorias /></RequireAuthUser>}/>
              {/* doctor pages */}
              <Route path="detalle/:tx" element={<AuditoriasDetalle/>}/>
              <Route path="pacientes" element={<RequireProfessionalUser><Patients /></RequireProfessionalUser>} />

            </Route>
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </>
      }
    </>
  )
}

export default App

function RequireAuthUser({ children }) {
  const user = useSelector(state => state.auth.user)
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function RequireProfessionalUser({ children }) {
  const user = useSelector(state => state.auth.user)
  const location = useLocation();

  if (!user || user.role !== roles.doctor) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}