import moment from "moment"
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

import othersActions from "../../../redux/actions/others"
import { useFormik } from "formik";
import { useState } from "react";
import { lookupPhone } from "../../../libs/twilio";
import scheduleActions from "../../../redux/actions/schedule";
import { personalInputs } from "../../../data/inputs";
import axios from "../../../interceptor/ApiInterceptor";

export const usePatientForm = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const appointment = useSelector(state => state.schedule)
  const isLoading = useSelector(state => state.others.isLoading)

  const showLogin = useSelector(state => state.others.showLogin)
  const openLogin = () => {
    if(appointment?.patient?.idType && appointment?.patient?.idNumber) {
      navigate(`/login${`/${appointment?.patient?.idType}`}${`/${appointment?.patient?.idNumber}`}?/agenda/paciente`)
    } else {
      navigate(`/login?/agenda/paciente`)
    }
  }
  
  const patientSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Escribe más de 2 carácteres como mínimo').max(50, 'Too Long!').required('Este campo es obligatorio'),
    surname: Yup.string().min(2, 'Escribe más de 2 carácteres como mínimo').max(50, 'Too Long!').required('Este campo es obligatorio'),
    idType: Yup.string().required('Este campo es obligatorio').oneOf(['DNI', 'PAS', 'CI']),
    phone: Yup.number().required('Este campo es obligatorio').min(1000000000, 'Este campo es obligatorio'),
    gender: Yup.string().required('Este campo es obligatorio'),

    mail: Yup.string().email('Verifica que sea un email válido').required('Este campo es obligatorio'),
    idNumber: Yup.string().when('idType', {
        is: "DNI",
        then: schema => schema.min(5, 'Tu DNI debe ser mayor o igual a 5 cifras').max(8, 'Tu DNI debe ser menor o igual a 8 cifras'),
      }).required('Este campo es obligatorio'),
    birthDate: Yup.string().required('Este campo es obligatorio').nullable()//.max(new Date(), 'La fecha declarada no puede ser posterior a hoy').min(new Date("01-01-1900"), "La fecha declarada debe ser posterior a 01/01/1900")
  });

  const [twilioError, setTwilioError] = useState("")
  const [documentValidation, setDocumentValidation] = useState({
    exist: true, searched: false, error: "", loading: false
  })

  const updatePatient = (values) => {
    dispatch(scheduleActions.updateAppointment({...appointment, patient: {...appointment?.patient, ...values}}))
  }

  const resetDocument = () => {
    setDocumentValidation({exist: true, searched: false, error: ""})
    updatePatient({idNumber: "", idType: ""})
  }

  const validateDocument = (values) => {
    
    setDocumentValidation({...documentValidation, loading: true})

    const url = `${process.env.REACT_APP_JAVA_BE_URL}/external/api/v1/security/prelogin`
    const payload = {idNumber: values?.idNumber, idType: values?.idType}

    axios.post(url, payload)
      .then(res => {
        

        if(res.data && res.data !== "") {
          setDocumentValidation({
            exist: true, 
            searched: true, 
            error: "Tu documento está asociado a una cuenta activa, para continuar", 
            loading: false
          })
        } else {
          setDocumentValidation({exist: false, searched: true, error: "", loading: false})
          updatePatient({...payload})
          setValuesInFormik()
        }
      })
      .catch(error => {
        console.log(error);
        setDocumentValidation({
          exist: true, 
          searched: false, 
          error: "Hubo un problema validando tu documento, vuelve a intentarlo nuevamente"
        })

        setDocumentValidation({exist: true, searched: true, error: "", loading: false})
        updatePatient({idNumber: "", idType: ""})
      })
  }

  const formik = useFormik({
    initialValues: appointment.patient ? appointment.patient : (user ? user : {}),
    validationSchema: !user ? patientSchema : undefined,

    onSubmit: async values => {
      // console.log('values personal data', values)

      dispatch(othersActions.setIsLoading(true))
      const output = await lookupPhone(values.phone)

      if(!output?.mobile) {
        setTwilioError('Ingresá un número celular válido')
        dispatch(othersActions.setIsLoading(false))
      } else {
        setTimeout(() => dispatch(othersActions.setIsLoading(false)), 2000)
        dispatch(scheduleActions.updateAppointment({...appointment, patient: values}))
        navigate('/agenda/cobertura')
      }
      
    }
  })

  const setValuesInFormik = () => {
    formik.setFieldValue('idType', appointment?.patient?.idType)
    formik.setFieldValue('idNumber', appointment?.patient?.idNumber)
  }

  const conditions = { address: appointment?.shop?.label === "A domicilio" }

  const loggedSubmit = () => {
    dispatch(scheduleActions.updateAppointment({...appointment, patient: user}))
    navigate('/agenda/cobertura')
  }

  return {
    appointment, 
    formik, 
    // date, 
    inputs: personalInputs, 
    conditions, 
    isLoading, 
    user, 
    navigate, 
    openLogin, 
    loggedSubmit,
    twilioError,
    documentValidation,
    validateDocument,
    resetDocument,
    updatePatient
  }
}
