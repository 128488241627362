import axios from 'axios';

const apiClient = axios.create();

apiClient.interceptors.request.use(
  (config) => {
    const userData = localStorage.getItem('user'); // Obtener el string del usuario
    if (userData) {
      const user = JSON.parse(userData); // Convertirlo en objeto
      if (user.token) {
        config.headers.Authorization = `Bearer ${user.token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default apiClient;
