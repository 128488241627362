import { Link, useOutletContext } from "react-router-dom"
import { ArrowCalendar } from "../AppointmentSchedule"
import { useConfirmAppointment } from "../customHooks"
import moment from 'moment';
import { sl } from "date-fns/locale";

const ConfirmAppointment = () => {
  const generalData = useOutletContext()

  const {date, appointment, isLoading, birthDate, confirmData, isDomi} = useConfirmAppointment(generalData.files)

  const direccionGorina = "Calle 485 N° 4685 Esq. 138" //appointment?.shop?.place.address +" - "+ appointment?.shop?.place.city
  // Crear un objeto moment para manipular la fecha
  const isGorina = appointment?.shop?.value.includes('GORINA');
  const preDate = moment(new Date(appointment?.date));
  
  const gap = isGorina
  ? preDate.day() === 6 // Si es sábado
    ? 3 // 3 horas para GORINA los sábados
    : 4 // 4 horas para GORINA en otros días
  : 4; // 4 horas para las demás tiendas
  
  const hour = Number(appointment?.hour?.hour ? appointment?.hour?.hour : "00");
  const minute = appointment?.minute?.minute ? appointment?.minute?.minute : "00";
  const formattedMinute = String(minute).padStart(2, "0");
  const [onlyDate, initialTime] = date.split(" - "); 

  let date2;
  if (isGorina) {
    date2 = `${onlyDate} - Entre ${initialTime} y ${hour + gap}:${formattedMinute} hs`;
  } else {
    date2 = date; // Mantiene el formato original si no es Gorina
  }
  
  return (
    <div className="mx-auto max-w-max">
      <div className='mb-5'>
        <Link to="/agenda/cobertura" className='flex items-center justify-start gap-2 max-w-max mb-2'>
          <ArrowCalendar left color="fill-cyan h-3" /> <p className='text-md font-bold text-cyan opacity-60'>Volver</p>
        </Link>
        <h1 className='font-bold text-xl sm:text-[28px] xl:text-4xl'>
          Confirmá los <span className='text-cyan'>datos</span> de tu <span className='text-cyan'>turno</span>
        </h1>
        <p className='text-grey text-sm mt-1 md:text-lg xl:text-[25px]'>
          Revisá la información que ingresaste, no podrás modificarla después de confirmar el turno.
        </p>
      </div>
      {!isLoading && <div className='flex flex-col lg:flex-row gap-5 w-full w-[300px] 400:w-[380px] 500:w-[480px] 600:w-[580px] md:w-full'>

        <div className='bg-cyan lg:bg-darkBlue shadow-2 p-4 lg:p-6 rounded-lg flex flex-col justify-center gap-4'>
          <div>
            <span className='text-white opacity-50 mb-1 uppercase text-sm'>Análisis</span>
            <p className='text-white text-lg'>{appointment?.test?.name}</p>
          </div>
          {appointment?.shop?.value !== "DOMI_EXTRACCION" ? (
            <div>
              <span className='text-white opacity-50 mb-1 uppercase text-sm'>Fecha y hora</span>
              <p className='text-white text-lg'>{date2}</p>
            </div>
          ) : (
            <div>
              <span className='text-white opacity-50 mb-1 uppercase text-sm'>Fecha y hora</span>
              <p className='text-white text-lg'>A definir.</p>
            </div>
          )}
          <div>
            <span className='text-white opacity-50 mb-1 uppercase text-sm'>Domicilio de extracción</span>
            <div>
              {isDomi 
                ? <p className='text-white text-lg'>Calle {appointment?.streetName} N {appointment?.streetNumber}, {appointment?.city}</p>
                : <p className='text-white text-lg'>{appointment?.shop?.value.includes('SUC13') ? 
                        'Av. 13 N° 215 - La Plata' : appointment?.shop?.value.includes('SUC14')? 
                        'Av. 14 N° 280 - La Plata': appointment?.shop?.value.includes('SUCGORINA')?
                        'Calle 485 N° 4685 Esq. 138': 'Dirección no informada'}</p>
              }
            </div>
          </div>
          <div>
            <span className='text-white opacity-50 mb-1 uppercase text-sm'>Archivos adjuntos</span>
            <p className='text-white text-lg'>
              {generalData?.files?.credential.length > 0 ? 'Adjuntaste la credencial' : 'No adjuntaste credencial'}
            </p>
            <p className='text-white text-lg'>
              {generalData?.files?.authorizations.length} {generalData?.files?.authorizations.length === 1 ? 'órden médica' : 'órdenes médicas'}
            </p>
          </div>
        </div>

        <div className='bg-white shadow-2 p-4 md:p-6 rounded-lg flex-1'>
          <h5 className='uppercase font-bold mb-3 text-lg'>Datos personales</h5>
          <div className='flex flex-col gap-1'>
            <div>
              <span className='text-grey uppercase text-[10px]'>Nombre y apellido</span>
              <p className='text-lg'>
                {appointment?.patient?.name} {appointment?.patient?.surname}
              </p>
            </div>
            <div>
              <span className='text-grey uppercase text-[10px]'>Documento</span>
              <p className='text-lg'>{appointment?.patient?.idType} {appointment?.patient?.idNumber}</p>
            </div>
            <div>
              <span className='text-grey uppercase text-[10px]'>Nacimiento</span>
              <p className='text-lg'>{birthDate}</p>
            </div>
            <div>
              <span className='text-grey uppercase text-[10px]'>Teléfono</span>
              <p className='text-lg'>{appointment?.patient?.phone}</p>
            </div>
            <div>
              <span className='text-grey uppercase text-[10px]'>Cobertura</span>
              <p className='text-lg'>{appointment?.healthInsurance?.name}</p>
            </div>
            <div>
              <span className='text-grey uppercase text-[10px]'>Email</span>
              <p className='text-lg'>{appointment?.patient?.mail}</p>
            </div>
          </div>
        </div>

      </div>}
      <div className="w-full flex justify-between gap-4 mt-8">
        <Link
          to="/agenda/cobertura"
          className='flex-1 border border-cyan text-cyan py-4 px-6 400:px-12 rounded-lg flex-1 opacity-70 md:flex-0 text-center'
        >
          Volver
        </Link>
        <button
          onClick={confirmData}
          className="bg-cyan text-white py-3 px-8 rounded-lg flex-1"
        >
          {isDomi ? 'Solicitar turno' : 'Confirmar turno'}
        </button>
      </div>
    </div>
  )
}

export default ConfirmAppointment
