import axios from "../../interceptor/ApiInterceptor"
import moment from "moment"
import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { shops, tests } from "../../data/appointments"

export const useCancelAppointment = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [appointment, setAppointment] = useState({scheduleEngine: {date: new Date()}})
  const [isCancelled, setIsCancelled] = useState(false)
  
  
  const {tokenId} = useParams()
  const navigate = useNavigate()
  const {REACT_APP_JAVA_BE_URL} = process.env

  const searchAppointment = () => {
    const url = `${REACT_APP_JAVA_BE_URL}/external/api/v2/addressschedule/byToken/${tokenId}`

    axios.get(url)
      .then(res => {
        // console.log(res.data);
        if(res.data.status === "CANCELLED") setIsCancelled(true)
        else {
          const hour = res.data.scheduleRange.hour
          const minute = res.data.scheduleRange.minute
          const preDate = moment(res.data?.scheduleRange?.schedulerEngine?.date)
          const date = `${preDate.format('dddd D')} de ${preDate.format('MMMM')} - ${hour}:${minute === 0 ? "00" : minute} hs`

          const shop = shops.find(shop => res.data.scheduleRange.schedulerEngine.shop.startsWith(shop.value))
          const test = tests.map(test => {return {...test, shops: test.shops.map(shop => {return shop.value})}}).find(test => test.shops.includes(res.data.scheduleRange.schedulerEngine.shop))

          setAppointment({
            date,
            shop: shop.label,
            address: res.data.scheduleRange.schedulerEngine.shop.toLowerCase().includes("domi") ? res.data.user.address : `${shop.place.address} ${shop.place.city}`,
            test: test.name
          })
          setIsLoading(false)
        }
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false)
      })
  }

  const cancelAppointment = () => {
    setIsLoading(true)
    //const url = `${REACT_APP_JAVA_BE_URL}/external/api/v2/addressschedule/cancel2/${tokenId}`
    const url = `${REACT_APP_JAVA_BE_URL}/external/api/v2/addressschedule/cancel2`

    const payload = {tokenId:tokenId}

    axios.post(url,payload)
      .then(res => {
        // console.log(res.data);
        setIsCancelled(true)
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false)
      })
  }

  useEffect(() => {
    searchAppointment()
  }, [])

  return {
    cancelAppointment,
    isLoading,
    appointment,
    navigate,
    isCancelled
  }
}
