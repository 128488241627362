import axios from "../../interceptor/ApiInterceptor"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { MaterialIcon } from "../../components/Icons"
import authActions from "../../redux/actions/auth"
import pdfExample from './pdfExample.json'

export const useResultsList = () => {
  const [ results, setResults ] = useState([])
  const [errorModal, setErrorModal] = useState({show: false, text: ""})
  const user = useSelector(state => state.auth.user)
  const dispatch = useDispatch()

  const BE_URL = process.env.REACT_APP_JAVA_BE_URL

  const searchResults = () => {
    const url = `${BE_URL}/external/api/v1/pdf/user/requests`
    let data = {
      idType: user?.idType,
      idNumber: user?.idNumber
    }
    axios.post(url, data, { headers: { Authorization: `Bearer ${user?.token}` } })
      .then(res => {
        // console.log(res.data);
        if(res.data) setResults(res.data)
      })
      .catch(error => {
        console.log(error);
        if(error.response && error.response.status === 401) dispatch(authActions.signOut(true))
      })
  }
  

  const downloadPDF = (number) => {
    const url = `${BE_URL}/external/api/v1/pdf/user/requests/pdf`

    let data = {
      requestNumber: number,
      idType: user?.idType, 
      idNumber: user?.idNumber
    }
    axios.post(url, data, { headers: { Authorization: `Bearer ${user?.token}` } })
      .then(res => {
        console.log(res.data); //20908017

        if (res.data.filedata) {



          const base64Pdf = res.data.filedata;

          // Check if the base64 string is valid
          if (!base64Pdf) {
            console.error('No base64 PDF received.');
            return;
          }

          // Remove the base64 header if present
          const base64String = base64Pdf.replace(/^data:application\/pdf;base64,/, '');

          //Convert base64 to a Uint8Array
          const binaryString = window.atob(base64String);
          const len = binaryString.length;
          const bytes = new Uint8Array(len);

          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
           }

          // Create a blob from the Uint8Array
          const blob = new Blob([bytes], { type: 'application/pdf' });

          // Create a link element
          //const link = document.createElement('a');
          const url = URL.createObjectURL(blob);
          //link.href = url;
          //link.setAttribute('download', `${number}.pdf`); // Set the file name

          // Append to the document and trigger the download
          //document.body.appendChild(link);
          //link.click();

          // Clean up
          //document.body.removeChild(link);
          //URL.revokeObjectURL(url); // Release the blob URL







          const downloadLink = document.createElement("a");
          downloadLink.href = url ;//`data:application/pdf;base64,${res.data.filedata}`;
          downloadLink.setAttribute('download', `${number}.pdf`);
          downloadLink.click()
        } else if (res.data.errorCode === 200) {
          const isAdministrative = res.data.errorMessage.includes('cuota permitida')

          setErrorModal({
            show: true,
            text: "",
            title: `${isAdministrative ? 'Trámite administrativo pendiente de finalización' : 'Su solicitud está en proceso'}. Ante cualquier duda comuníquese por alguno de los siguientes medios:`,
            type: "info"
          })
        }
      })
      .catch(error => {
        console.log(error);

        if (error.response && error.response.status === 401) dispatch(authActions.signOut(true))
        else {
          toast({
            title: 'Error descargando el archivo',
            desc: 'Intentá nuevamente en unos minutos.',
            type: "error",
            icon: <MaterialIcon icon="error" extrastyles="text-white" />
          })
        }
      })
  }
 
  useEffect(() => {
    searchResults()
  }, [])

  return {
    results, downloadPDF, user, errorModal, setErrorModal
  }
}