import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as Yup from 'yup';
import { useFormik } from 'formik';
import scheduleActions from "../../../redux/actions/schedule";
import othersActions from "../../../redux/actions/others";
import moment from "moment"

import obras from '../AppointmentSchedule/obras.json'
import { useEffect, useState } from "react";

export const useInsuranceForm = (files, setFiles) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const appointment = useSelector(state => state.schedule)

  const isDomi = appointment?.shop?.value.includes('DOMI')

  const validarCadenaNoVacia = (message) => {
    return Yup.string().test(
      'is-not-empty',
      message,
      value => value && value.trim() !== ''
    ).required();
  };
  

  const insuranceSchema = Yup.object().shape({
    streetName: !isDomi ? Yup.string() : validarCadenaNoVacia('No olvidés agregar la calle'),
    streetNumber: !isDomi ? Yup.string() : validarCadenaNoVacia('No olvidés agregar la altura'),
    city: !isDomi ? Yup.string() : validarCadenaNoVacia('No olvidés agregar la localidad'),
    streetDescription: !isDomi ? Yup.string() : validarCadenaNoVacia('No olvidés agregar entre calles'),
    healthInsurance: Yup.object().required("No olvidés elegir tu cobertura"),
  });

  const [orders, setOrders] = useState([])

  const initialValues = {
    streetName: appointment?.streetName ? appointment?.streetName : undefined,
    streetNumber: appointment?.streetNumber ? appointment?.streetNumber : undefined,
    city: appointment?.city ? appointment?.city : undefined,
    streetDescription: appointment?.streetDescription ? appointment?.streetDescription : undefined,
    healthInsurance: appointment?.healthInsurance ? appointment?.healthInsurance : undefined
  }

  const withInitial = appointment?.address !== "" && appointment?.healthInsurance?.id !== undefined

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: insuranceSchema,

    onSubmit: values => {
      console.log('values insurance data', values)

      try {
        const authorizations = values?.orders && values?.orders.length > 0 ? values?.orders.map(order => {
          return {data: order.data, type: order.type}
        }) : []
  
        const credential = values?.credential ? [{
          data: values?.credential?.data,
          type: values?.credential?.type
        }] : []
  
        dispatch(othersActions.setIsLoading(true))
        setFiles({authorizations, credential})
        dispatch(scheduleActions.updateAppointment({
          ...appointment, 
          healthInsurance: values?.healthInsurance, 
          // files: [...authorizations, ...credential],
          streetName: values?.streetName ? values?.streetName : "",
          streetNumber: values?.streetNumber ? values?.streetNumber : "",
          streetDescription: values?.streetDescription ? values?.streetDescription : "",
          city: values?.city ? values?.city : ""
        }))
        dispatch(othersActions.setIsLoading(false))
        navigate('/agenda/confirmacion')
      } catch (error) {
        console.log(error);
      }
    }
  })

  const addFileToOrders = (file) => {
    setOrders([...orders, {...file}])
    formik.setFieldValue('orders', [...orders, {...file}])
  }

  const deleteOrder = (orderToDelete) => {
    setOrders(orders.filter(order => order !== orderToDelete))
  }

  const preDate = moment(appointment?.date)
  const date = `${preDate.format('dddd D')} de ${preDate.format('MMMM')} - ${appointment?.hour?.hour}:${appointment?.hour?.minute} hs`

  return { 
    appointment, 
    date, 
    formik,
    isDomi,
    addFileToOrders,
    orders,
    deleteOrder
  }
}